import React, { Component, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import _ from 'lodash';
import qs from 'query-string';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Product from '../components/product';
import Category from '../components/category';

const SearchList = props => {
  const {
    data,
    location: { search },
  } = props;
  const [searchList, setWordPressSearch] = useState([]);
  const parsed = qs.parse(search);

  const { keyword = '' } = parsed;
  useEffect(async () => {
    const result = await axios.get(
      'https://cms.wowindow.tw/wp-json/wp/v2/posts?_embed',
      {
        params: {
          search: keyword,
        },
      },
    );
    setWordPressSearch(_.get(result, 'data', []));
  }, []);
  const postsData = searchList.map(o => {
    return {
      node: {
        slug: o.slug,
        featured_media: {
          source_url: _.get(o, '_embedded.wp:featuredmedia.0.source_url', ''),
          title: _.get(o, 'title.rendered', ''),
        },
        title: _.get(o, 'title.rendered', ''),
        categories: _.get(o, '_embedded.wp:term.0', []),
      },
    };
  });
  const breadcrumbs = [
    {
      data: [
        {
          name: '搜尋',
          url: `/search?keyword=${keyword}`,
        },
      ],
    },
  ];

  return (
    <Layout showHeader={true} breadcrumbs={breadcrumbs}>
      <SEO title="WoWindow" />
      <div style={{ minHeight: 10 }}>
        <Product title={`搜尋: ${keyword}`} edges={postsData} />
      </div>

      {/* TODO: 搜尋分頁 */}
      {/* <div style={{ margin: 20 }}>
        <Pagination totalPage={10} activePage={1} baseUrl={`/`} />
      </div> */}

      <Category edges={data.allWordpressCategory.edges} />

      <style jsx>{``}</style>
    </Layout>
  );
};

export default SearchList;

export const pageQuery = graphql`
  query {
    allWordpressCategory(filter: { slug: { nin: "noad" } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
  }
`;
